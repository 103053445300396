
const config: IConfig = {
  serve: 'https://d5rap2.mez100.com.cn/back',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
